import styled from 'styled-components'
import { palette } from '~components/homepage/styled/constants'

export const FormWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  .buttons {
    margin-top: 40px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr max-content max-content;
  }
`

export const FieldRow = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;

  .image-preview {
    width: 100px;
    border-radius: 10px;
  }

  .images {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 10px;
  }
`

export const FieldLabel = styled.div`
  opacity: 1;
`

export const FieldItem = styled.div`
  .rdw-editor-wrapper {
    border: 1px solid ${palette.inputBorder};
    max-width: 100%;
  }

  .rdw-editor-main {
    padding: 0px 12px;
  }

  .ant-select {
    width: 100%;

    .ant-select-selection {
      display: block;
      height: auto;
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      box-shadow: 0 0 0 30px white inset !important;
      border: 1px solid ${palette.inputBorder};
      border-radius: 0px;
      color: ${palette.darkBlue};
      outline: none;
      background: white;
      font-size: 12px;
      padding: 15px;
      text-align: left;
      line-height: initial;

      .ant-select-selection__rendered {
        line-height: initial;
        margin: 0 auto;
        width: 105%;
        z-index: 10;
      }
    }
  }

  input {
    max-width: 393px;
    width: 100%;
    border: 1px solid ${palette.inputBorder};
    border-radius: 0px;
    color: ${palette.darkBlue};
    outline: none;
    background: white;
    font-size: 12px;
    padding: 15px;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
`
