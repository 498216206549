import React, { useState } from 'react'
import styled from 'styled-components'
import { palette } from '~components/homepage/styled/constants'
import { DarkButton } from '~components/homepage/styled/shared'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import {
  firebaseAuth,
  firebaseGoogleAuthProvider,
  MASTER_ACC,
} from '~src/api/auth-firebase-api'
import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth'

const TrippleKlos = styled(SVGImage)`
  stroke: white;
  fill: ${palette.darkBlue};
  stroke-width: 1px;
`

const LoginWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${palette.darkBlue};
  color: ${palette.beige};
`

export const AuthComponent: React.FC = ({ children }): React.ReactElement => {
  const [currentUser, setCurrentUser] = useState<User>()

  const authGoogle = () => {
    signInWithPopup(firebaseAuth, firebaseGoogleAuthProvider)
      .then(result => {
        GoogleAuthProvider.credentialFromResult(result)
        const { user } = result

        const allowedUser = user.email === MASTER_ACC

        if (!allowedUser) {
          signOut(firebaseAuth)

          return
        }

        setCurrentUser(user)
      })
      .catch(error => {
        GoogleAuthProvider.credentialFromError(error)
      })
  }

  return currentUser?.uid ? (
    <> {children} </>
  ) : (
    <LoginWrapper>
      <div>
        <TrippleKlos
          svgProps={[KlosySVGId.klos, 'klosy-knives-logo', 60, 260]}
        />
        <TrippleKlos
          svgProps={[KlosySVGId.klos, 'klosy-knives-logo', 60, 260]}
        />
        <TrippleKlos
          svgProps={[KlosySVGId.klos, 'klosy-knives-logo', 60, 260]}
        />
      </div>
      <div>Kłosy admin</div>

      <DarkButton style={{ marginTop: 40 }} onClick={(): void => authGoogle()}>
        Login
      </DarkButton>
    </LoginWrapper>
  )
}
