import React, { useEffect, useState, useRef, SyntheticEvent } from 'react'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

import styled from 'styled-components'
import uuid from 'react-uuid'

import { Button } from '~components/homepage/styled/shared'

import '../../fonts/css/averta-font.css'

import { StoreItemAccessory } from '~src/models/store'

import { FieldItem, FieldLabel, FieldRow, FormWrapper } from './shared'

const initData = {
  id: uuid(),
  images: [],
  soldOut: false,
  price: ['0', '0'],
  comment: '',
  description: ['', ''],
  name: ['', ''],
  subtitle: ['', ''],
}

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const AccessoryForm: React.FC<{
  item?: StoreItemAccessory
  handleCreateItem: (id: string, ietmData: StoreItemAccessory) => void
  handleUpdateItem: (id: string, ietmData: StoreItemAccessory) => void
  handleDeleteItem: (id: string) => void
}> = ({
  item,
  handleCreateItem,
  handleUpdateItem,
  handleDeleteItem,
}): React.ReactElement => {
  const [selectedFile, setSelectedFile] = useState(null)
  const fileInputRef = useRef(null)

  const [itemData, setItemData] = useState<StoreItemAccessory>(initData)

  useEffect((): void => {
    if (item) {
      setItemData(item)
    } else {
      setItemData(initData)
    }
  }, [item])

  const changeFileHandler = (event): void => {
    setSelectedFile(event.target.files[0])
  }

  const storage = getStorage()

  const handleSubmission = (): void => {
    const imageUuid = `${itemData.id}-${Date.now()}`
    const storageRef = ref(storage, imageUuid)

    uploadBytes(storageRef, selectedFile).then((): void => {
      getDownloadURL(ref(storage, imageUuid)).then((url): void => {
        setItemData(
          (v): StoreItemAccessory => ({ ...v, images: [...v.images, url] })
        )
        setSelectedFile(undefined)
        fileInputRef.current.value = ''
      })
    })
  }

  return (
    <div>
      <FormWrapper>
        <FieldRow>
          <FieldLabel>Nazwa (pl)</FieldLabel>
          <FieldItem>
            <input
              type="text"
              value={itemData.name[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    name: [value, v.name[1]],
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Nazwa (en)</FieldLabel>
          <FieldItem>
            <input
              type="text"
              value={itemData.name[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    name: [v.name[0], value],
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Subtytul (pl)</FieldLabel>
          <FieldItem>
            <input
              type="text"
              value={itemData.subtitle[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    subtitle: [value, v.subtitle[1]],
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Subtytul (en)</FieldLabel>
          <FieldItem>
            <input
              type="text"
              value={itemData.subtitle[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    subtitle: [v.subtitle[0], value],
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>zł</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="pln"
              type="text"
              value={itemData.price[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItem => ({
                    ...v,
                    price: [value, v?.price?.[1]],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>euro</FieldLabel>
          <FieldItem>
            <input
              id="euro"
              type="text"
              value={itemData.price[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    price: [v.price[0], value],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>soldout</FieldLabel>
          <div>
            <input
              label=""
              type="checkbox"
              value={itemData.soldOut}
              onChange={(e: SyntheticEvent): void => {
                const { checked } = e?.target

                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    soldOut: checked,
                  })
                )
              }}
            />
          </div>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Opis (pl)</FieldLabel>
          <FieldItem>
            <textarea
              style={{ width: '100%', height: '70px' }}
              value={itemData.description[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    description: [value, v.description[1]],
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Opis (en)</FieldLabel>
          <FieldItem>
            <textarea
              style={{ width: '100%', height: '70px' }}
              value={itemData.description[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    description: [v.description[0], value],
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Komentarz</FieldLabel>
          <FieldItem>
            <textarea
              style={{ width: '100%', height: '70px' }}
              value={itemData.comment}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItemAccessory => ({
                    ...v,
                    comment: value,
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Zdjęcia</FieldLabel>
          <div>
            <div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={changeFileHandler}
              />
              <br />
              {selectedFile && (
                <LinkButton
                  onKeyPress={(): void => {}}
                  role="button"
                  tabIndex={0}
                  onClick={(): void => handleSubmission()}
                >
                  Dodaj to zdjęcie
                </LinkButton>
              )}
            </div>

            <div className="images">
              {itemData.images.map(
                (url): React.ReactElement => (
                  <div key={url}>
                    <img
                      className="image-preview"
                      alt="knife"
                      width="100px"
                      src={url}
                    />
                    <div>
                      <LinkButton
                        onKeyPress={(): void => {}}
                        role="button"
                        tabIndex={0}
                        onClick={(): void =>
                          setItemData(
                            (v): StoreItemAccessory => ({
                              ...v,
                              images: v.images.filter(
                                (u): boolean => u !== url
                              ),
                            })
                          )
                        }
                      >
                        Usuń
                      </LinkButton>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </FieldRow>

        <div className="buttons">
          <div />
          {item ? (
            <div>
              <Button
                onClick={(): void => {
                  // eslint-disable-next-line
                  const response = confirm('usunąć pozycję?')

                  if (response) {
                    handleDeleteItem(itemData.id)
                  }
                }}
              >
                USUŃ
              </Button>
            </div>
          ) : (
            <div />
          )}
          <div>
            <Button
              onClick={(): void =>
                item
                  ? handleUpdateItem(itemData.id, itemData)
                  : handleCreateItem(itemData.id, itemData)
              }
            >
              {item ? 'ZAPISZ ZMIANY' : 'DODAJ POZYCJĘ'}
            </Button>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}
