import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { firebaseApp } from './firebase-init'

export const firebaseAuth = getAuth(firebaseApp)
firebaseAuth.languageCode = 'pl'

export const firebaseGoogleAuthProvider = new GoogleAuthProvider()

firebaseGoogleAuthProvider.setCustomParameters({
  login_hint: 'user@klosy.pl',
})

export const MASTER_ACC = 'klosy.roboczy@gmail.com'
