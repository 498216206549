import React, { useEffect, useState } from 'react'
import {
  doc,
  collection,
  getDocs,
  setDoc,
  deleteDoc,
} from 'firebase/firestore/lite'

import styled from 'styled-components'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import { DarkButton } from '~components/homepage/styled/shared'

import { StoreItem, StoreItemAccessory } from '~src/models/store'

import { storeDb } from '~src/api/store-api'

import { Workshop } from '~src/models/workshop'
import { signOut } from 'firebase/auth'
import { firebaseAuth } from '~src/api/auth-firebase-api'
import { ItemForm } from './item-form'
import { AccessoryForm } from './accessory-form'
import { WorkshopForm } from './workshop-form'

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-rows: min-content minmax(min-content, max-content);
  gap: 20px;
  height: 100%;

  input,
  textarea {
    max-width: 100%;
  }

  .DraftEditor-root {
    height: 230px;
  }
`

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  gap: 10px;
  margin-bottom: 15px;
  min-height: 90px;

  .desc {
    display: grid;
    grid-template-rows: 1fr min-content;

    .info {
      display: flex;
      flex-direction: row;
      font-family: 'Averta-Semibold', sans-serif;
    }

    .price {
      font-size: 16px;
    }

    .bottom {
      opacity: 0.5;
      font-size: 14px;
    }
  }

  .images {
    gap: 3px;
    display: flex;
    flex-direction: row;
    & > img {
      width: 100px;
      border-radius: 10px;
    }

    .small {
      display: flex;
      flex-direction: column;
      gap: 3px;
      width: 25px;
      img {
        width: 25px;
        border-radius: 5px;
      }
    }
  }
`

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 100px;
  margin-top: 20px;
`

const SmallButton = styled(DarkButton)`
  padding: 5px 10px;
  font-size: 9px;
  padding-top: 7px;
`

export const AdminStoreItems = (): React.ReactElement => {
  const [storeItems, setStoreItems] = useState<StoreItem[]>()
  const [storeAccessories, setStoreAccessories] = useState<
    StoreItemAccessory[]
  >([])
  const [workshops, setWorkshops] = useState<Workshop[]>([])

  const [edit, setEdit] = useState<StoreItem>()
  const [editAccessory, setEditAccessory] = useState<StoreItemAccessory>()
  const [editWorkshop, setEditWorkshop] = useState<Workshop>()
  const [loading, setLoading] = useState<boolean>(true)

  const fetchStoreItems = async (): Promise<void> => {
    const store = collection(storeDb, 'store')
    const items = await getDocs(store)
    setLoading(false)

    setStoreItems(items.docs.map((d): StoreItem => d.data()) as StoreItem[])
  }

  const fetchAccessoryItems = async (): Promise<void> => {
    const store = collection(storeDb, 'accessory')
    const items = await getDocs(store)
    setLoading(false)

    setStoreAccessories(
      items.docs.map(
        (d): StoreItemAccessory => d.data()
      ) as StoreItemAccessory[]
    )
  }

  const fetchWorkshops = async (): Promise<void> => {
    const store = collection(storeDb, 'workshop')
    const items = await getDocs(store)
    setLoading(false)

    setWorkshops(items.docs.map((d): Workshop => d.data()) as Workshop[])
  }

  useEffect((): void => {
    fetchStoreItems()
    fetchAccessoryItems()
    fetchWorkshops()
  }, [])

  const handleCreateItem = async (id, itemData): Promise<void> => {
    setLoading(true)
    await setDoc(doc(storeDb, 'store', id), {
      ...itemData,
      dateCreated: new Date().toISOString().slice(0, 10),
    })
    fetchStoreItems()
  }

  const handleUpdateItem = async (id, itemData): Promise<void> => {
    setLoading(true)
    await setDoc(doc(storeDb, 'store', id), itemData)
    fetchStoreItems()
  }

  const handleDeleteItem = async (id): Promise<void> => {
    setLoading(true)
    await deleteDoc(doc(storeDb, 'store', id))
    fetchStoreItems()
  }

  const handleCreateAccessory = async (id, data): Promise<void> => {
    setLoading(true)
    await setDoc(doc(storeDb, 'accessory', id), {
      ...data,
      dateCreated: new Date().toISOString().slice(0, 10),
    })
    fetchAccessoryItems()
  }

  const handleUpdateAccessory = async (id, data): Promise<void> => {
    setLoading(true)
    await setDoc(doc(storeDb, 'accessory', id), data)
    fetchAccessoryItems()
  }

  const handleDeleteAccessory = async (id): Promise<void> => {
    setLoading(true)
    await deleteDoc(doc(storeDb, 'accessory', id))
    fetchAccessoryItems()
  }

  const handleCreateWorkshop = async (id, data): Promise<void> => {
    setLoading(true)

    await setDoc(doc(storeDb, 'workshop', id), {
      ...data,
      dateCreated: new Date().toISOString().slice(0, 10),
    })
    fetchWorkshops()
  }

  const handleUpdateWorkshop = async (id, data): Promise<void> => {
    setLoading(true)
    await setDoc(doc(storeDb, 'workshop', id), data)
    fetchWorkshops()
  }

  const handleDeleteWorkshop = async (id): Promise<void> => {
    setLoading(true)
    await deleteDoc(doc(storeDb, 'workshop', id))
    fetchWorkshops()
  }

  return (
    <ItemsWrapper>
      <HeaderRow>
        {loading ? (
          <div style={{ height: 40 }}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 18 }} spin />}
            />
          </div>
        ) : (
          <SVGImage
            svgProps={[KlosySVGId.logoMini, 'klosy-knives-logo', 10, 40]}
          />
        )}
        Kłosy admin
        <LinkButton
          onKeyPress={(): void => {}}
          role="button"
          tabIndex={0}
          onClick={() => {
            signOut(firebaseAuth).then(() => {
              console.log('success')

              if (typeof window !== 'undefined') {
                window.location = '/'
              }
            })
          }}
        >
          logout
        </LinkButton>
      </HeaderRow>
      <div></div>
      <div>
        <h2>
          Wszystkie pozycje{' '}
          <SmallButton onClick={(): void => setEdit(undefined)}>
            DODAJ NOWY NOZ
          </SmallButton>
        </h2>
      </div>
      <div>
        <h2> {edit ? `Edycja noza ${edit.knife}` : `Nowy nóz`} </h2>
      </div>
      <ItemWrapper>
        {storeItems?.map(
          (s): React.ReactElement => (
            <ItemRow key={s.id}>
              <div className="images">
                <img src={s.images[0]} alt={s.id} />
                <div className="small">
                  {s.images.map((src, index): React.ReactElement | null =>
                    index ? <img key={src} src={src} alt={src} /> : null
                  )}
                </div>
              </div>

              <div className="desc">
                <div className="top">
                  <div className="info">
                    <div>{s.knife} /</div>
                    <div>{s.metal} /</div>
                    <div>{s.wood}</div>
                  </div>

                  <div className="price">
                    <span>
                      {s.price[0]}zł / {s.price[1]}euro{' '}
                    </span>
                    <LinkButton
                      onKeyPress={(): void => {}}
                      role="button"
                      tabIndex={0}
                      onClick={(): void =>
                        setEdit({ ...s, comment: s?.comment || '' })
                      }
                    >
                      Edytuj
                    </LinkButton>
                  </div>
                </div>

                <div className="bottom">
                  {s.dateCreated} {s.id}{' '}
                </div>
              </div>
            </ItemRow>
          )
        )}
      </ItemWrapper>
      <div>
        <ItemForm
          item={edit}
          handleUpdateItem={handleUpdateItem}
          handleCreateItem={handleCreateItem}
          handleDeleteItem={handleDeleteItem}
        />
      </div>

      <div>
        <h2>
          Akcesoria{' '}
          <SmallButton onClick={(): void => setEditAccessory(undefined)}>
            DODAJ NOWA AKCESORJĘ
          </SmallButton>
        </h2>{' '}
      </div>
      <div>
        <h2>
          {' '}
          {editAccessory
            ? `Edycja noza ${editAccessory.name[0]}`
            : `Nowa akcesoria`}{' '}
        </h2>
      </div>
      <ItemWrapper>
        {storeAccessories?.map(
          (s): React.ReactElement => (
            <ItemRow key={s.id}>
              <div className="images">
                <img src={s.images[0]} alt={s.id} />
                <div className="small">
                  {s.images.map((src, index): React.ReactElement | null =>
                    index ? <img key={src} src={src} alt={src} /> : null
                  )}
                </div>
              </div>

              <div className="desc">
                <div className="top">
                  <div className="info">
                    <div>{s.name[0]} </div>
                  </div>

                  <div className="price">
                    <span>
                      {s.price[0]}zł / {s.price[1]}euro{' '}
                    </span>
                    <LinkButton
                      onKeyPress={(): void => {}}
                      role="button"
                      tabIndex={0}
                      onClick={(): void =>
                        setEditAccessory({ ...s, comment: s?.comment || '' })
                      }
                    >
                      Edytuj
                    </LinkButton>
                  </div>
                </div>

                <div className="bottom">
                  {s.dateCreated} {s.id}{' '}
                </div>
              </div>
            </ItemRow>
          )
        )}
      </ItemWrapper>
      <div>
        <AccessoryForm
          item={editAccessory}
          handleUpdateItem={handleUpdateAccessory}
          handleCreateItem={handleCreateAccessory}
          handleDeleteItem={handleDeleteAccessory}
        />
      </div>

      <div>
        <h2>
          Warsztaty{' '}
          <SmallButton onClick={(): void => setEditWorkshop(undefined)}>
            DODAJ NOWY WARSZTAT
          </SmallButton>
        </h2>{' '}
      </div>
      <div>
        <h2>
          {' '}
          {editWorkshop
            ? `Edycja warsztatu ${editWorkshop.title[0]}`
            : `Nowy warsztat`}{' '}
        </h2>
      </div>
      <ItemWrapper>
        {workshops?.map(
          (w): React.ReactElement => (
            <ItemRow key={w.id}>
              <div className="images">
                <img src={w.image} alt={w.id} />
              </div>

              <div className="desc">
                <div className="top">
                  <div className="info">
                    <div>{w.title[0]} </div>
                  </div>

                  <div className="price">
                    <span>
                      {w.price[0]}zł / {w.price[1]}euro{' '}
                    </span>
                    <LinkButton
                      onKeyPress={(): void => {}}
                      role="button"
                      tabIndex={0}
                      onClick={(): void =>
                        setEditWorkshop({
                          ...w,
                          description: w?.description || '',
                        })
                      }
                    >
                      Edytuj
                    </LinkButton>
                  </div>
                </div>

                <div className="bottom">
                  {w.dateCreated} {w.id}{' '}
                </div>
              </div>
            </ItemRow>
          )
        )}
      </ItemWrapper>
      <div>
        <WorkshopForm
          item={editWorkshop}
          handleUpdateItem={handleUpdateWorkshop}
          handleCreateItem={handleCreateWorkshop}
          handleDeleteItem={handleDeleteWorkshop}
        />
      </div>
    </ItemsWrapper>
  )
}
