import React, { useEffect, useState, useRef, SyntheticEvent } from 'react'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

import styled from 'styled-components'
import { Select } from 'antd'
import uuid from 'react-uuid'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

import { Button, PurifyLink } from '~components/homepage/styled/shared'

import '../../fonts/css/averta-font.css'

import { SVGImage } from '~components/homepage/svg/SVGImage'
import { Workshop } from '~src/models/workshop'
import { workshopIcons } from '~src/models/workshop/map-icons'

import 'draft-js/dist/Draft.css'
import { EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import _ from 'lodash'
import { FieldItem, FieldLabel, FieldRow, FormWrapper } from './shared'
import { SelectWrapper } from '../homepage/styled/order-modal-components'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let Editor
import('react-draft-wysiwyg').then(function(mod) {
  // eslint-disable-next-line prefer-destructuring
  Editor = mod.Editor
})

const initData = {
  id: uuid(),
  image: undefined,
  icon: undefined,
  title: ['', ''],
  time: ['', ''],
  date: [''],
  description: ['', ''],
  price: ['0', '0'],
  prepayment: ['0', '0'],
  peopleCapacity: ['', ''],
  dateCreated: '',
  order: '',
}

const { Option } = Select

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const WorkshopForm: React.FC<{
  item?: Workshop
  handleCreateItem: (id: string, ietmData: Workshop) => void
  handleUpdateItem: (id: string, ietmData: Workshop) => void
  handleDeleteItem: (id: string) => void
}> = ({
  item,
  handleCreateItem,
  handleUpdateItem,
  handleDeleteItem,
}): React.ReactElement => {
  const [selectedFile, setSelectedFile] = useState(null)
  const fileInputRef = useRef(null)

  const [itemData, setItemData] = useState<Workshop>(initData)

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  const [editorStateEN, setEditorStateEN] = useState(() =>
    EditorState.createEmpty()
  )

  const preprocessedUpdate = (id, data) => {
    const serializedData = {
      ...data,
      description: [
        convertToHTML({
          entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
              return (
                <a href={entity.data.url} target="_blank" rel="noreferrer">
                  {originalText}
                </a>
              )
            }
            return originalText
          },
        })(editorState.getCurrentContent()),
        convertToHTML(editorStateEN.getCurrentContent()),
      ],
    }

    handleUpdateItem(id, serializedData)
  }

  useEffect((): void => {
    if (item) {
      setItemData(item)
      setEditorState(
        EditorState.createWithContent(convertFromHTML(item.description[0]))
      )
      setEditorStateEN(
        EditorState.createWithContent(convertFromHTML(item.description[1]))
      )
    } else {
      setItemData(initData)
      setEditorState(EditorState.createEmpty())
      setEditorStateEN(EditorState.createEmpty())
    }
  }, [item])

  const changeFileHandler = (event): void => {
    setSelectedFile(event.target.files[0])
  }

  const storage = getStorage()

  const handleSubmission = (): void => {
    const imageUuid = `${itemData.id}-${Date.now()}`
    const storageRef = ref(storage, imageUuid)

    uploadBytes(storageRef, selectedFile).then((): void => {
      getDownloadURL(ref(storage, imageUuid)).then((url): void => {
        setItemData((v): Workshop => ({ ...v, image: url }))
        setSelectedFile(undefined)
        fileInputRef.current.value = ''
      })
    })
  }

  return (
    <div>
      <FormWrapper>
        <FieldRow>
          <FieldLabel>Order</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="order"
              type="number"
              value={itemData.order}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    order: value,
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Nazwa (pl)</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="pln"
              type="text"
              value={itemData.title[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    title: [value, v?.title?.[1]],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Nazwa (en)</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="pln"
              type="text"
              value={itemData.title[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    title: [v?.title?.[0], value],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Czas trwania (pl)</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="pln"
              type="text"
              value={itemData.time[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    time: [value, v?.time?.[1]],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Czas trwania (en)</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="pln"
              type="text"
              value={itemData.time[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    time: [v?.time?.[0], value],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>
        {_.map(itemData?.date, (date, index) => {
          return (
            <FieldRow key={index}>
              <FieldLabel>Data ({index + 1} opcja)</FieldLabel>
              <FieldItem>
                <input
                  label=""
                  id="pln"
                  type="text"
                  placeholder="26.05 - 27.05"
                  value={date}
                  onChange={(e: SyntheticEvent): void => {
                    const { value } = e.target

                    const copied = [...itemData.date]
                    copied[index] = value

                    setItemData(
                      (v): Workshop => ({
                        ...v,
                        date: copied,
                      })
                    )
                  }}
                />{' '}
                {itemData?.date.length !== 1 && (
                  <PurifyLink
                    onClick={() => {
                      const copied = [...itemData.date]
                      copied.splice(index, 1)
                      return setItemData(
                        (v): Workshop => ({
                          ...v,
                          date: copied,
                        })
                      )
                    }}
                  >
                    {' '}
                    usuń -{' '}
                  </PurifyLink>
                )}
                {index + 1 === itemData?.date.length && (
                  <PurifyLink
                    onClick={() =>
                      setItemData(
                        (v): Workshop => ({
                          ...v,
                          date: [...v.date, ''],
                        })
                      )
                    }
                  >
                    {' '}
                    dodaj +{' '}
                  </PurifyLink>
                )}
              </FieldItem>
            </FieldRow>
          )
        })}

        <FieldRow>
          <FieldLabel>Opis (pl)</FieldLabel>
          <FieldItem>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Opis (en)</FieldLabel>
          <FieldItem>
            <Editor
              editorState={editorStateEN}
              onEditorStateChange={setEditorStateEN}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>zł</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="pln"
              type="text"
              value={itemData.price[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    price: [value, v?.price?.[1]],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>euro</FieldLabel>
          <FieldItem>
            <input
              id="euro"
              type="text"
              value={itemData.price[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    price: [v.price[0], value],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>przedpłata (zł)</FieldLabel>
          <FieldItem>
            <input
              id="zl-prep"
              type="text"
              value={itemData.prepayment[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    prepayment: [value, v.prepayment[1]],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>przedpłata (euro)</FieldLabel>
          <FieldItem>
            <input
              id="euro-prep"
              type="text"
              value={itemData.prepayment[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    prepayment: [v.prepayment[0], value],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Ilość osób (pl)</FieldLabel>
          <FieldItem>
            <input
              id="capa-pl"
              type="text"
              value={itemData.peopleCapacity[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    peopleCapacity: [value, v.peopleCapacity[1]],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Ilość osób (en)</FieldLabel>
          <FieldItem>
            <input
              id="capa-en"
              type="text"
              value={itemData.peopleCapacity[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): Workshop => ({
                    ...v,
                    peopleCapacity: [v.peopleCapacity[0], value],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Zdjęcia</FieldLabel>
          <div>
            <div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={changeFileHandler}
              />
              <br />
              {selectedFile && (
                <LinkButton
                  onKeyPress={(): void => {}}
                  role="button"
                  tabIndex={0}
                  onClick={(): void => handleSubmission()}
                >
                  Dodaj to zdjęcie
                </LinkButton>
              )}
            </div>

            <div className="images">
              {(itemData?.image || item?.image) && (
                <div key={item?.image}>
                  <img
                    className="image-preview"
                    alt="knife"
                    width="100px"
                    src={item?.image || itemData?.image}
                  />
                  <div>
                    <LinkButton
                      onKeyPress={(): void => {}}
                      role="button"
                      tabIndex={0}
                      onClick={(): void =>
                        setItemData(
                          (v): Workshop => ({
                            ...v,
                            image: '',
                          })
                        )
                      }
                    >
                      Usuń
                    </LinkButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Ikonka</FieldLabel>
          <FieldItem>
            <SelectWrapper className="generic-dropdown" width="1">
              <Select
                getPopupContainer={(trigger): any => trigger.parentNode}
                showArrow={false}
                value={itemData.icon}
                onChange={(value: string): void =>
                  setItemData(
                    (v): Workshop => ({
                      ...v,
                      icon: value,
                    })
                  )
                }
              >
                {Object.entries(workshopIcons).map(([key, value]) => (
                  <Option value={key} key={key}>
                    <span className="icon-option">{value}</span>
                  </Option>
                ))}
              </Select>
              <SVGImage
                svgProps={[KlosySVGId.arrowDown, 'dropdown-arrow', 19, 11]}
              />
            </SelectWrapper>
          </FieldItem>
        </FieldRow>

        <div className="buttons">
          <div />
          {item ? (
            <div>
              <Button
                onClick={(): void => {
                  // eslint-disable-next-line
                  const response = confirm('usunąć warsztat?')

                  if (response) {
                    handleDeleteItem(itemData.id)
                  }
                }}
              >
                USUŃ
              </Button>
            </div>
          ) : (
            <div />
          )}
          <div>
            <Button
              onClick={(): void =>
                item
                  ? preprocessedUpdate(itemData.id, itemData)
                  : handleCreateItem(itemData.id, itemData)
              }
            >
              {item ? 'ZAPISZ ZMIANY' : 'DODAJ WARSZTAT'}
            </Button>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}
