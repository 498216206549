import React, { ReactElement } from 'react'

import '../i18n/i18n'

import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import SVGSymbols from '~components/homepage/svg/icons/svg'
import CookiesPopup from '~components/homepage/sections/cookies-popup'
import {
  Container,
  ContentContainer,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../fonts/css/averta-font.css'

import GlobalStyle from '~components/homepage/sections/global-styles'

import { AuthComponent, AdminStoreItems } from '~components/admin'

const StyledContentContainer = styled(ContentContainer)`
  overflow: auto;
  padding-bottom: 50px;
`

const AdminPage = ({ t, i18n }): ReactElement => {
  return (
    <SectionWrapper style={{ height: '100vh' }}>
      <Container style={{ height: '100%' }}>
        <GlobalStyle />
        <LanguageContext.Provider value={{ t, lang: i18n.language }}>
          <CookiesPopup />
          <SVGSymbols />
          <AuthComponent>
            <StyledContentContainer>
              <AdminStoreItems />
            </StyledContentContainer>
          </AuthComponent>
        </LanguageContext.Provider>
      </Container>
    </SectionWrapper>
  )
}

export default withTranslation('translation')(AdminPage)
