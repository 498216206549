import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useContext,
  SyntheticEvent,
} from 'react'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

import styled from 'styled-components'
import { Select } from 'antd'
import uuid from 'react-uuid'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

import { Button } from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../../fonts/css/averta-font.css'

import { StoreItem } from '~src/models/store'

import sharedWoodModel from '~src/models/wood/Shared.wood'
import sharedMetalModel from '~src/models/metals/Shared.metal'
import sharedKnivesModel from '~src/models/knives/Shared.knives'

import { SVGImage } from '~components/homepage/svg/SVGImage'
import { palette } from '~components/homepage/styled/constants'
import { SelectWrapper } from '../homepage/styled/order-modal-components'
import { FieldItem, FieldLabel, FieldRow, FormWrapper } from './shared'
import { findById, byLocal } from './utils'

const initData = {
  id: uuid(),
  knife: undefined,
  images: [],
  metal: undefined,
  wood: undefined,
  soldOut: false,
  price: ['0', '0'],
  comment: '',
}

const LinkButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const ItemForm: React.FC<{
  item?: StoreItem
  handleCreateItem: (id: string, ietmData: StoreItem) => void
  handleUpdateItem: (id: string, ietmData: StoreItem) => void
  handleDeleteItem: (id: string) => void
}> = ({
  item,
  handleCreateItem,
  handleUpdateItem,
  handleDeleteItem,
}): React.ReactElement => {
  const { t } = useContext(LanguageContext)
  const [selectedFile, setSelectedFile] = useState(null)
  const fileInputRef = useRef(null)

  const [itemData, setItemData] = useState<StoreItem>(initData)

  useEffect((): void => {
    if (item) {
      setItemData(item)
    } else {
      setItemData(initData)
    }
  }, [item])

  const changeFileHandler = (event): void => {
    setSelectedFile(event.target.files[0])
  }

  const storage = getStorage()

  const handleSubmission = (): void => {
    const imageUuid = `${itemData.id}-${Date.now()}`
    const storageRef = ref(storage, imageUuid)

    uploadBytes(storageRef, selectedFile).then((): void => {
      getDownloadURL(ref(storage, imageUuid)).then((url): void => {
        setItemData((v): StoreItem => ({ ...v, images: [...v.images, url] }))
        setSelectedFile(undefined)
        fileInputRef.current.value = ''
      })
    })
  }

  const generateDropdown = (
    collection,
    currentValue,
    onChange
  ): ReactElement => {
    return (
      <SelectWrapper className="generic-dropdown" width="1">
        <Select
          getPopupContainer={(trigger): any => trigger.parentNode}
          showArrow={false}
          value={currentValue}
          onChange={(id: string): void =>
            onChange(findById(id, collection).name)
          }
        >
          {collection.map(
            (c: any): ReactElement => (
              // eslint-disable-next-line
              <Option value={c.id} key={`${byLocal(c, 'EN')}-dropdown`}>
                <span className="material-name">{c.name}</span>
              </Option>
            )
          )}
        </Select>
        <SVGImage svgProps={[KlosySVGId.arrowDown, 'dropdown-arrow', 19, 11]} />
      </SelectWrapper>
    )
  }

  const steelDropdown = generateDropdown(
    sharedMetalModel,
    itemData.metal,
    (v): void => setItemData((i): StoreItem => ({ ...i, metal: v }))
  )
  const woodDropdown = generateDropdown(
    sharedWoodModel,
    itemData.wood,
    (v): void => setItemData((i): StoreItem => ({ ...i, wood: v }))
  )

  const knifeDropdown = generateDropdown(
    sharedKnivesModel,
    itemData.knife,
    (v): void => setItemData((i): StoreItem => ({ ...i, knife: v }))
  )

  return (
    <div>
      <FormWrapper>
        <FieldRow>
          <FieldLabel color={palette.darkBlue} fs="14px">
            Nóz
          </FieldLabel>
          <FieldItem>{knifeDropdown}</FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.steelKind')}</FieldLabel>
          <FieldItem> {steelDropdown} </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>{t('order-modal.woodKind')}</FieldLabel>
          <FieldItem> {woodDropdown} </FieldItem>
        </FieldRow>
        <FieldRow>
          <FieldLabel>zł</FieldLabel>
          <FieldItem>
            <input
              label=""
              id="pln"
              type="text"
              value={itemData.price[0]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItem => ({
                    ...v,
                    price: [value, v?.price?.[1]],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>euro</FieldLabel>
          <FieldItem>
            <input
              id="euro"
              type="text"
              value={itemData.price[1]}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItem => ({
                    ...v,
                    price: [v.price[0], value],
                  })
                )
              }}
            />{' '}
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>soldout</FieldLabel>
          <div>
            <input
              label=""
              type="checkbox"
              value={itemData.soldOut}
              onChange={(e: SyntheticEvent): void => {
                const { checked } = e?.target

                setItemData(
                  (v): StoreItem => ({
                    ...v,
                    soldOut: checked,
                  })
                )
              }}
            />
          </div>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Komentarz</FieldLabel>
          <FieldItem>
            <textarea
              style={{ width: '100%', height: '70px' }}
              value={itemData.comment}
              onChange={(e: SyntheticEvent): void => {
                const { value } = e.target
                setItemData(
                  (v): StoreItem => ({
                    ...v,
                    comment: value,
                  })
                )
              }}
            />
          </FieldItem>
        </FieldRow>

        <FieldRow>
          <FieldLabel>Zdjęcia</FieldLabel>
          <div>
            <div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={changeFileHandler}
              />
              <br />
              {selectedFile && (
                <LinkButton
                  onKeyPress={(): void => {}}
                  role="button"
                  tabIndex={0}
                  onClick={(): void => handleSubmission()}
                >
                  Dodaj to zdjęcie
                </LinkButton>
              )}
            </div>

            <div className="images">
              {itemData.images.map(
                (url): React.ReactElement => (
                  <div key={url}>
                    <img
                      className="image-preview"
                      alt="knife"
                      width="100px"
                      src={url}
                    />
                    <div>
                      <LinkButton
                        onKeyPress={(): void => {}}
                        role="button"
                        tabIndex={0}
                        onClick={(): void =>
                          setItemData(
                            (v): StoreItem => ({
                              ...v,
                              images: v.images.filter(
                                (u): boolean => u !== url
                              ),
                            })
                          )
                        }
                      >
                        Usuń
                      </LinkButton>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </FieldRow>

        <div className="buttons">
          <div />
          {item ? (
            <div>
              <Button
                onClick={(): void => {
                  // eslint-disable-next-line
                  const response = confirm('usunąć noz?')

                  if (response) {
                    handleDeleteItem(itemData.id)
                  }
                }}
              >
                USUŃ
              </Button>
            </div>
          ) : (
            <div />
          )}
          <div>
            <Button
              onClick={(): void =>
                item
                  ? handleUpdateItem(itemData.id, itemData)
                  : handleCreateItem(itemData.id, itemData)
              }
            >
              {item ? 'ZAPISZ ZMIANY' : 'DODAJ NOZ'}
            </Button>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}
